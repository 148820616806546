import { SportMetaData } from "common";

export const ALL_ODD_OPTIONS: Option[] = [
  {
    label: "All Odds",
    value: "all",
  },
  {
    label: "Moneyline",
    value: "moneyline",
  },
  {
    label: "Spread",
    value: "spread",
  },
  {
    label: "Total",
    value: "total",
  },
];

export const ODD_OPTIONS: Option[] = [
  {
    label: "Moneyline",
    value: "moneyline",
  },
  {
    label: "Spread",
    value: "spread",
  },
  {
    label: "Total",
    value: "total",
  },
];

export const SPORTS: SportMetaData[] = [
  { id: "nfl", label: "NFL", icon: "football" },
  { id: "nba", label: "NBA", icon: "basketball" },
  { id: "mlb", label: "MLB", icon: "baseball" },
  { id: "nhl", label: "NHL", icon: "hockey-puck" },
];

export const COMING_SOON_SPORTS: SportMetaData[] = [
  { id: "soccer", label: "Soccer", icon: "soccer" },
  { id: "ncaaf", label: "NCAAF", icon: "football" },
  { id: "ufc", label: "UFC", icon: "ufc" },
  { id: "ncaab", label: "NCAAB", icon: "basketball" },
  { id: "boxing", label: "Boxing", icon: "boxing" },
  { id: "wnba", label: "WNBA", icon: "basketball" },
  { id: "golf", label: "Golf", icon: "golf" },
  { id: "cfl", label: "CFL", icon: "football" },
];


export const SPORT_OPTIONS: Option[] = [
  ...SPORTS.map((sport, i) => ({
    value: sport.id,
    label: sport.label,
    icon: sport.icon,
  })),
];

export const ALL_SPORT_OPTIONS: Option[] = [
  {
    label: "All",
    value: "all",
  },
  ...SPORTS.map((sport, i) => ({
    value: sport.id,
    label: sport.label,
    icon: sport.icon,
  })),
];

export const ALL_SPORT_OPTIONS_DETAILED: Option[] = [
  {
    label: "All Sports",
    value: "all",
  },
  ...SPORTS.map((sport, i) => ({
    value: sport.id,
    label: sport.label,
    icon: sport.icon,
  })),
];
import axios, { AxiosRequestConfig, Method } from "axios";


const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL || "https://dev.api.moneyline.com",
});

const request = async (
  url: string,
  method: Method,
  data?: object,
  token?: string | null,
  config?: AxiosRequestConfig
): Promise<any> => {
  const headers: Record<string, string> = {};
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const response = await axiosInstance.request({
    url,
    method,
    data,
    ...config,
    headers: {
      ...headers,
      ...config?.headers,
    },
  });

  return {
    data: response.data,
    status: response.status,
    headers: response.headers,
  };
};

const mlClient = {
  get: (url: string, token?: string | null, config?: AxiosRequestConfig) =>
    request(url, "GET", undefined, token, {
      ...config,
      headers: {
        ...config?.headers,
        "Cache-Control": "no-store",
      }
    }),
  post: (url: string, data: object, token?: string | null, config?: AxiosRequestConfig) =>
    request(url, "POST", data, token, config),
  put: (url: string, data?: object, token?: string | null, config?: AxiosRequestConfig) =>
    request(url, "PUT", data, token, config),
  delete: (url: string, token?: string | null, config?: AxiosRequestConfig) =>
    request(url, "DELETE", undefined, token, config),
};

export default mlClient;

"use client";
import { OddsFormat } from "common";
import React, { createContext, useContext, useState } from "react";

interface OddsFormatContext {
  oddsFormat: OddsFormat;
  setOddsFormat: (format: OddsFormat) => void;
}
const OddsFormatContext = createContext<OddsFormatContext | undefined>(
  undefined
);

export const useOddsFormat = (): OddsFormatContext => {
  const context = useContext(OddsFormatContext);
  if (!context) {
    throw new Error("useOddsFormat must be used within a OddsFormatProvider");
  }
  return context;
};


export const OddsFormatProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [oddsFormat, setOddsFormatState] = useState<OddsFormat>("american");

  React.useEffect(() => {
    const storedFormat = localStorage.getItem('oddsFormat');
    if (storedFormat) {
      setOddsFormatState(storedFormat as OddsFormat);
    }
  }, []);

  const setOddsFormat = (format: OddsFormat) => {
    setOddsFormatState(format);
    if (typeof window !== 'undefined') {
      localStorage.setItem('oddsFormat', format);
    }
  };

  return (
    <OddsFormatContext.Provider value={{ oddsFormat, setOddsFormat }}>
      {children}
    </OddsFormatContext.Provider>
  );
};

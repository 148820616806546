"use client";
import { useState, useEffect, useRef } from "react";

interface WebSocketOptions {
  onOpen?: () => void;
  onClose?: () => void;
  onMessage?: (data: any) => void;
  onError?: (error: Event) => void;
  maxReconnectAttempts?: number;
}

const useWebSocket = (url: string, options: WebSocketOptions = {}) => {
  const [data, setData] = useState<any | null>(null);
  const socket = useRef<WebSocket | null>(null);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const isConnecting = useRef(false);
  const reconnectAttempts = useRef<number>(0);
  const shouldReconnect = useRef<boolean>(true);
  const maxReconnectAttempts = options.maxReconnectAttempts || 86400;

  const connectWebSocket = () => {
    if (isConnecting.current) return;
    isConnecting.current = true;

    if (
      socket.current &&
      socket.current.readyState !== WebSocket.CLOSED &&
      socket.current.readyState !== WebSocket.CLOSING
    ) {
      isConnecting.current = false;
      return;
    }

    socket.current = new WebSocket(url);

    socket.current.onopen = () => {
      console.log("WebSocket Connected");
      setIsConnected(true);
      reconnectAttempts.current = 0;
      shouldReconnect.current = true;
      isConnecting.current = false;
      options.onOpen?.();
    };

    socket.current.onmessage = (event: MessageEvent) => {
      console.log("Received raw data:", event);
      try {
        const parsedData = JSON.parse(event.data);
        if (parsedData.type === "ping") {
          socket.current?.send(JSON.stringify({ type: "pong" }));
          console.log("Sent pong in response to server ping");
          return;
        }
        setData(parsedData);
        options.onMessage?.(parsedData);
      } catch (error) {
        console.error("Error parsing WebSocket message", error);
        options.onError?.(error as Event);
      }
    };

    socket.current.onerror = (error: Event) => {
      console.error("WebSocket Error", error);
      options.onError?.(error);
      isConnecting.current = false;
    };

    socket.current.onclose = (e: CloseEvent) => {
      console.log("WebSocket Disconnected");
      setIsConnected(false);
      isConnecting.current = false;
      if (
        shouldReconnect.current &&
        reconnectAttempts.current < maxReconnectAttempts
      ) {
        setTimeout(() => {
          reconnectAttempts.current++;
          console.log(
            `Attempting to reconnect (${reconnectAttempts.current}/${maxReconnectAttempts})...`
          );
          connectWebSocket();
        }, Math.min(1000 * reconnectAttempts.current, 10000));
      }
      options.onClose?.();
    };
  };

  const checkAndReconnect = () => {
    if (
      socket.current?.readyState === WebSocket.CLOSED ||
      socket.current?.readyState === WebSocket.CLOSING
    ) {
      console.log("Reconnecting WebSocket due to visibility change...");
      connectWebSocket();
    }
  };

  useEffect(() => {
    connectWebSocket();

    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        checkAndReconnect();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      shouldReconnect.current = false;
      if (socket.current) {
        socket.current.close();
      }
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [url]);

  return [data, socket.current, isConnected];
};

export default useWebSocket;

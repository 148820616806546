"use client";
import { formatDate, formatTime } from "@/utils";
import { Sport } from "common";
import React, { HTMLAttributes } from "react";
import LiveIndicator from "./live-indicator";
import { useCountdown } from "@/utils/hooks/countdown";

export interface TimestampProps extends HTMLAttributes<HTMLDivElement> {
  dateTime: string;
  timeZone?: string;
  platform: string;
  dark?: boolean;
  compact?: boolean;
  sport?: Sport;
  live?: boolean;
  closed?: boolean;
}

export default function Timestamp(props: TimestampProps) {
  const countdown = useCountdown(props.dateTime);
  let showCountdown = false;
  let startsNow = false;
  let days = Number(countdown.days);
  let hours = Number(countdown.hours);
  let minutes = Number(countdown.minutes);
  let seconds = Number(countdown.seconds);
  if (hours < 1 && days < 1 && !props.live && !props.closed) {
    showCountdown = true;
    if (hours === 0 && minutes === 0 && seconds === 0) {
      startsNow = true;
    }
  }
  const classes: string = `inline-flex flex-row items-center uppercase gap-1 ${
    props.dark ? "text-white" : "text-dark"
  } ${props.className}`;
  if (!props.compact) {
    return (
      <div className={classes}>
        {props.live ? (
          <LiveIndicator />
        ) : props.dateTime ? (
          <>
            {showCountdown ? (
              <>
                {startsNow ? (
                  <span>STARTS NOW</span>
                ) : (
                  <span>
                    STARTS IN {countdown.minutes}:{countdown.seconds}
                  </span>
                )}
              </>
            ) : (
              <>
                <span>{formatDate(props.dateTime, true, true, true)}</span>
                <span>•</span>
                <span>{formatTime(props.dateTime, true)}</span>
              </>
            )}
          </>
        ) : (
          <span>N/A</span>
        )}
        <span>/</span>
        <span>{props.platform || "N/A"}</span>
      </div>
    );
  }
  return (
    <div className={classes}>
      {props.dateTime ? (
        <>
          <div className="flex flex-col gap-y-1 justify-center items-center">
            <div className="text-title-lg-bold text-dark flex items-center gap-1">
              {props.live ? (
                <LiveIndicator />
              ) : showCountdown ? (
                <>
                  {startsNow ? (
                    <span>STARTS NOW</span>
                  ) : (
                    <span>
                      STARTS IN {countdown.minutes}:{countdown.seconds}
                    </span>
                  )}
                </>
              ) : (
                formatTime(props.dateTime, true)
              )}{" "}
              / {props.platform || "N/A"}
            </div>
            {!props.live && (
              <span
                className={`text-body-sm ${
                  props.dark ? "text-secondary-700" : "text-secondary-400"
                }`}
              >
                {formatDate(props.dateTime, true, true, true)}
              </span>
            )}
          </div>
        </>
      ) : (
        <span>N/A</span>
      )}
    </div>
  );
}
